@import '~@/assets/scss/main.scss';

.subscription-card {
    height: 100%;

    @include media-breakpoint-down(lg) {
        height: auto;
    }
}

.gray-button-background {
    background-color: #c8c5c5;
}

.subscription-logo {
    max-height: 10rem;

    @include media-breakpoint-down(xl) {
        max-width: 80%;
        margin-bottom: 1rem;
    }
}

.subscription-name {
    font-size: 1.25rem;
}

.product-actions {
    .col-lg {
        &:nth-child(1) > a {
            border-radius: 0rem 0rem 0rem $border-radius-card;
            @include media-breakpoint-down(lg) {
                border-radius: 0rem;
            }
        }

        &:nth-child(2) > a {
            border-radius: 0rem 0rem $border-radius-card;

            @include media-breakpoint-down(lg) {
                border-radius: 0rem 0rem $border-radius-card $border-radius-card;
            }
        }

        @include media-breakpoint-down(lg) {
            font-size: 0.75rem;
        }
    }
}
